/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DirectoryAreaModel } from '../models/DirectoryAreaModel';
import type { DirectoryModel } from '../models/DirectoryModel';
import type { DirectoryRoleModel } from '../models/DirectoryRoleModel';
import type { DirectorySaveModel } from '../models/DirectorySaveModel';
import type { DirectorySaveResult } from '../models/DirectorySaveResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DirectoryService {

    /**
     * Action: Build the directory cache in the background
     * @param apiKey Security-Key
     * @param groupUpn optional: rebuild only this group
     * @returns void 
     * @throws ApiError
     */
    public static buildCachePost(
apiKey?: string,
groupUpn?: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Directory/BuildCache',
            query: {
                'apiKey': apiKey,
                'groupUPN': groupUpn,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }

    /**
     * Action: Build the directory filter cache in the background
     * @param apiKey Security-Key
     * @returns void 
     * @throws ApiError
     */
    public static buildFilterCachePost(
apiKey?: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Directory/BuildFilterCache',
            query: {
                'apiKey': apiKey,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }

    /**
     * Get all directory-roles
     * @returns DirectoryRoleModel Success
     * @throws ApiError
     */
    public static getDirectoryRolesGet(): CancelablePromise<Array<DirectoryRoleModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Directory/GetDirectoryRoles',
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Get all areas for directory
     * @returns DirectoryAreaModel Success
     * @throws ApiError
     */
    public static getDirectoryAreasGet(): CancelablePromise<Array<DirectoryAreaModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Directory/GetDirectoryAreas',
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Query for users in directory
     * Either role or area must be passed!
     * @param role optional: Filter by Role/Role-Group
     * @param area optional: Filter by Region/National/Chapter
     * @param includeEmpty optional: if Edit enabled: include empty roles? (default: false)
     * @returns DirectoryModel Success
     * @throws ApiError
     */
    public static queryGet(
role?: string,
area?: string,
includeEmpty: boolean = false,
): CancelablePromise<Array<DirectoryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Directory/Query',
            query: {
                'role': role,
                'area': area,
                'includeEmpty': includeEmpty,
            },
            errors: {
                422: `Client Error`,
                424: `Client Error`,
            },
        });
    }

    /**
     * Get directory entries where user is member
     * Either role or area must be passed!
     * @param upn UPN of user
     * @returns DirectoryModel Success
     * @throws ApiError
     */
    public static memberOfGet(
upn?: string,
): CancelablePromise<Array<DirectoryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Directory/MemberOf',
            query: {
                'upn': upn,
            },
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Query for users in directory
     * Either role or area must be passed!
     * @param requestBody Updates for Directory-Entry
     * @returns DirectorySaveResult Success
     * @throws ApiError
     */
    public static setDirectoryMembersPost(
requestBody?: DirectorySaveModel,
): CancelablePromise<DirectorySaveResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Directory/SetDirectoryMembers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                406: `Not Acceptable`,
                422: `Client Error`,
            },
        });
    }

}
