/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SyncViewModel } from '../models/SyncViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SyncService {

    /**
     * Action: Sync Azure-AD to CISV-DB
     * @param startWith First letter of UPN to start with
     * @param endWith First letter of UPN to end with (including)
     * @param checkConsent Include check for consent-options?
     * @param batchSize optional: Size of batch (default: 100)
     * @returns SyncViewModel Success
     * @throws ApiError
     */
    public static syncAzureAdget(
startWith: string = 'A',
endWith: string = 'Z',
checkConsent: boolean = false,
batchSize: number = 100,
): CancelablePromise<SyncViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Sync/SyncAzureAD',
            query: {
                'startWith': startWith,
                'endWith': endWith,
                'checkConsent': checkConsent,
                'batchSize': batchSize,
            },
            errors: {
                403: `Forbidden`,
                422: `Client Error`,
            },
        });
    }

    /**
     * Action: Sync Azure-AD user to CISV-DB
     * @param upn UPN of user to sync
     * @param checkConsent Include check for consent-options?
     * @returns SyncViewModel Success
     * @throws ApiError
     */
    public static syncAzureAdUserGet(
upn?: string,
checkConsent: boolean = false,
): CancelablePromise<SyncViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Sync/SyncAzureADUser',
            query: {
                'upn': upn,
                'checkConsent': checkConsent,
            },
            errors: {
                403: `Forbidden`,
                422: `Client Error`,
            },
        });
    }

    /**
     * Action: Sync/Check Consent-Options
     * @param startWith First letter of UPN to start with
     * @param endWith First letter of UPN to end with (including)
     * @param batchSize optional: Size of batch (default: 100)
     * @returns SyncViewModel Success
     * @throws ApiError
     */
    public static syncMoodleBadgesGet(
startWith: string = 'A',
endWith: string = 'Z',
batchSize: number = 100,
): CancelablePromise<SyncViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Sync/SyncMoodleBadges',
            query: {
                'startWith': startWith,
                'endWith': endWith,
                'batchSize': batchSize,
            },
            errors: {
                403: `Forbidden`,
                422: `Client Error`,
            },
        });
    }

    /**
     * Action: Sync Base-Data in CISV-DB with SharePoint lists
     * @param batchSize optional: Size of batch (default: 100)
     * @returns SyncViewModel Success
     * @throws ApiError
     */
    public static syncSharePointListsGet(
batchSize: number = 100,
): CancelablePromise<SyncViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Sync/SyncSharePointLists',
            query: {
                'batchSize': batchSize,
            },
            errors: {
                403: `Forbidden`,
                422: `Client Error`,
            },
        });
    }

    /**
     * Action: Sync/Check Consent-Options
     * @param startWith First letter of UPN to start with
     * @param endWith First letter of UPN to end with (including)
     * @param batchSize optional: Size of batch (default: 100)
     * @returns SyncViewModel Success
     * @throws ApiError
     */
    public static syncUserConsentGet(
startWith: string = 'A',
endWith: string = 'Z',
batchSize: number = 100,
): CancelablePromise<SyncViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Sync/SyncUserConsent',
            query: {
                'startWith': startWith,
                'endWith': endWith,
                'batchSize': batchSize,
            },
            errors: {
                403: `Forbidden`,
                422: `Client Error`,
            },
        });
    }

}
