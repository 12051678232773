import * as React from "react";
import {
  Dropdown,
  DropdownProps,
  Checkbox,
  CheckboxProps,
  Button,
  FormProps,
  Form,
  Radio,
  Label,
  Header,
  FormFieldProps,
  TextAreaProps,
  TextArea,
  Segment,
} from "semantic-ui-react";
import {
  DateInputProps,
  DateInput,
  DatesRangeInputProps,
  DatesRangeInput,
  DateTimeInputProps,
  DateTimeInput,
  YearInputProps,
  YearInput,
} from "semantic-ui-calendar-react";
import { Col, Row } from "react-flexbox-grid";
import { FieldArray, ArrayHelpers } from "formik";
import * as Yup from "yup";
import { renderFormField } from "./Simpleform";
import { IFormField } from ".";
import { FormField } from "./FormField";
import { toDate } from "../../utils/toDate";
import Editor, { EditorProps } from "../Editor";

export const SimpleFormDropdown = React.memo(
  (props: DropdownProps & FormFieldProps) => {
    const { onChange, ...restProps } = props;
    return (
      <FormField
        {...restProps}
        control={(cprops: DropdownProps) => (
          <Dropdown
            {...cprops}
            fluid
            onChange={(e, { value }) => {
              props.setFieldValue(props.name, value);
            }}
          />
        )}
      />
    );
  }
);

class EditorComp extends React.Component<EditorProps & FormFieldProps> {
  render = () => {
    return (
      <Editor
        {...this.props}
        onChange={(html) => {
          this.props.setFieldValue(this.props.name, html);
        }}
      />
    );
  };
}
export const SimpleFormTextEditor = React.memo(
  (props: TextAreaProps & FormFieldProps) => {
    const { onChange, ...restProps } = props;
    return <FormField {...restProps} control={EditorComp} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name == nextProps.name &&
      prevProps.value === nextProps.value &&
      prevProps.error === nextProps.error
    );
  }
);

class TAComp extends React.Component<TextAreaProps> {
  render = () => {
    return (
      <TextArea
        {...this.props}
        onChange={(e, { value }) => {
          this.props.setFieldValue(this.props.name, value);
        }}
      />
    );
  };
}
export const SimpleFormTextAreaWithError = React.memo(
  (props: TextAreaProps & FormFieldProps) => {
    const { onChange, ...restProps } = props;
    let error = props.value && props.value.toString().length > 1300;
    return (
      <div>
        <FormField {...restProps} control={TAComp} />
        {error ? (
          <div className="errorArea">
            <span>
              <b>Your text exceeds the character limit allowed (1300).</b>
            </span>{" "}
          </div>
        ) : (
          <div className="errorArea">
            <span>&nbsp;</span>
          </div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name == nextProps.name && prevProps.value === nextProps.value
    );
  }
);
export const SimpleFormTextArea = React.memo(
  (props: TextAreaProps & FormFieldProps) => {
    const { onChange, ...restProps } = props;
    return <FormField {...restProps} control={TAComp} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name == nextProps.name && prevProps.value === nextProps.value
    );
  }
);

export const SimpleFormDateTimePicker = React.memo(
  (props: DateTimeInputProps & FormFieldProps) => {
    const { onChange, dateTimeFormat, ...restProps } = props;
    const formfieldProps: FormFieldProps = restProps;
    return (
      <FormField
        {...formfieldProps}
        control={(cprops: DateTimeInputProps) => {
          const { ...restProps } = cprops;
          return (
            <DateTimeInput
              {...restProps}
              value={toDate(cprops.value) as any}
              animation="fade"
              dateFormat="YYYY-MM-DD HH:mm"
              dateTimeFormat="YYYY-MM-DD HH:mm"
              initialDate={undefined}
              onChange={(e, { value }) => {
                props.setFieldValue(
                  props.name,
                  value && value.length > 1
                    ? value.toLocaleString(undefined, { hour12: false })
                    : ""
                );
                if (props.stringName && props.stringName.length > 1) {
                  props.setFieldValue(
                    props.stringName,
                    value && value.length > 1
                      ? value.toLocaleString(undefined, { hour12: false }) +
                          ":00"
                      : ""
                  );
                }
              }}
            />
          );
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name == nextProps.name && prevProps.value === nextProps.value
    );
  }
);

export const FormInputWithHiddenField = (props: any) => {
  return (
    <>
      <Form.Input {...props}></Form.Input>
      {props.className && props.className.includes("input-hidden") && (
        <span className="hidden-value">{props.value}</span>
      )}
    </>
  );
};

export const SimpleFormCheckbox = (props: CheckboxProps) => {
  const { checked, onChange, ...restProps } = props;
  return (
    <Form.Checkbox
      {...restProps}
      checked={!!props.value}
      onChange={(e, { checked }) => {
        props.setFieldValue(props.name, checked);
      }}
    />
  );
};

export const SimpleFormDatePicker = (props: DateInputProps) => {
  const { onChange, label, iconPosition, ...restProps } = props;
  return (
    <FormField
      label={label}
      {...restProps}
      control={(cprops: DateInputProps) => (
        <DateInput
          {...cprops}
          value={toDate(cprops.value) as any}
          timeZone="undefined"
          localization="en-GB"
          animation={"fade"}
          iconPosition={iconPosition}
          onChange={(e, data) => {
            props.setFieldValue(data.name, data.value);
            if (props.stringName && props.stringName.length > 1) {
              props.setFieldValue(
                props.stringName,
                data.value && data.value.length > 1 ? data.value : ""
              );
            }
          }}
        />
      )}
    />
  );
};

export const SimpleFormYearPicker = (props: YearInputProps) => {
  const { onChange, label, iconPosition, ...restProps } = props;
  return (
    <FormField
      label={label}
      {...restProps}
      control={(cprops: YearInputProps) => (
        <YearInput
          {...cprops}
          animation={"fade"}
          iconPosition={iconPosition}
          onChange={(e, data) => {
            props.setFieldValue(
              data.name,
              new Date(data.value).getFullYear().toString()
            );
          }}
        />
      )}
    />
  );
};

export const SimpleFormDateRangePicker = (props: DatesRangeInputProps) => {
  const { onChange, label, iconPosition, ...restProps } = props;
  return (
    <FormField
      label={label}
      {...restProps}
      control={(cprops: DatesRangeInputProps) => (
        <DatesRangeInput
          {...cprops}
          animation={"fade"}
          iconPosition={iconPosition}
          onChange={(e, { name, value }) => {
            props.setFieldValue(name, value);
          }}
        />
      )}
    />
  );
};

export const SimpleFormCheckboxYN = (props: DropdownProps) => {
  const { onChange, ...restProps } = props;
  return (
    props.options &&
    props.options.map((option) => {
      const componentNameValue: any = props.value;
      return (
        <Radio
          disabled={props.disabled}
          readOnly={props.readOnly}
          key={props.name + option.key}
          label={option.text}
          onChange={() => {
            props.setFieldValue(props.name, option.value);
          }}
          checked={componentNameValue === option.value}
        />
      );
    })
  );
};

export const SimpleFormCheckBoxFromOptions = (props: DropdownProps) => {
  const { onChange, ...restProps } = props;
  const checkedOptions: string[] = (props.value as any) || [];
  return props.options ? (
    <Row>
      {props.options.map((option) => {
        if (!option.value) {
          return null;
        }
        const isChecked = checkedOptions.includes(option.value.toString());
        return (
          <Col key={option.value + option.key} {...option.colSize}>
            <Checkbox
              disabled={props.disabled}
              readOnly={props.readOnly}
              name={"" + option.value}
              label={option.text}
              defaultChecked={isChecked}
              checked={isChecked}
              className="checkbox-options"
              onChange={(e, { checked }) => {
                if (checked) {
                  option.value &&
                    props.setFieldValue(props.name, [
                      ...checkedOptions,
                      option.value.toString(),
                    ]);
                } else {
                  option.value &&
                    props.setFieldValue(
                      props.name,
                      checkedOptions.filter(
                        (op) => option.value && op != option.value.toString()
                      )
                    );
                }
              }}
            />
          </Col>
        );
      })}
    </Row>
  ) : null;
};

export const YNOptions = [
  { key: 1, text: "Yes", value: "y" },
  { key: 2, text: "No", value: "n" },
];

export const formCheckBoxYN = (
  name: string,
  colSizeLg: number,
  colSizeClassName: string,
  defaultValue?: string
) => ({
  component: Form.Radio,
  props: {
    control: SimpleFormCheckboxYN,
    name: name,
    options: YNOptions,
    defaultValue: defaultValue,
  },
  colSize: {
    lg: colSizeLg,
    className: colSizeClassName,
  },
});

export const formInput = (
  name: string,
  colSizeLg: number,
  colSizeClassName: string,
  placeholder?: string,
  validation?: Yup.Schema<unknown> | Yup.Ref,
  defaultValue?: string
) => ({
  validation: validation,
  component: Form.Input,
  props: {
    name: name,
    defaultValue: defaultValue,
    placeholder: placeholder || "",
  },
  colSize: {
    lg: colSizeLg,
    className: colSizeClassName,
  },
});

export const SimpleFormFieldArray = (props: FormProps) => (
  <Segment>
    <FieldArray
      name={props.name}
      render={(arrayHelper: ArrayHelpers) => (
        <Row>
          <>
            {props.fields
              ? props.fields.map((field: IFormField, index: number) => {
                  return (
                    <Col key={index} {...field.colSize}>
                      {renderFormField(
                        field.component,
                        props.disabled,
                        props.readonly || field.props.readonly,
                        field.props
                      )}
                    </Col>
                  );
                })
              : null}
          </>
          {props.value &&
            props.value.map((rowData: any, rowIndex: number) => {
              return (
                <>
                  {props.fieldArrayFields
                    ? props.fieldArrayFields.map(
                        (field: IFormField, index: number) => {
                          return (
                            <Col key={index} {...field.colSize}>
                              {renderFormField(
                                field.component,
                                props.disabled,
                                props.readonly || field.props.readonly,
                                {
                                  ...field.props,
                                  value: rowData[field.props.name], // field.props || "",
                                  name: `${props.name}.${rowIndex}.${field.props.name}`,
                                },
                                undefined,
                                undefined,
                                rowData[field.props.name]
                              )}
                            </Col>
                          );
                        }
                      )
                    : null}
                </>
              );
            })}

          {!props.disabled && !props.readonly && (
            <Button
              type="button"
              content="add Row"
              onClick={() => {
                arrayHelper.push({});
              }}
            />
          )}
        </Row>
      )}
    />
  </Segment>
);

export const formLabel = (
  labelName: string,
  content: string,
  labelLg?: number,
  labelClassName?: string,
  defaultValue?: string
): IFormField => ({
  component: Label,
  props: {
    name: labelName,
    content: content,
    defaultValue: defaultValue,
  },
  colSize: {
    lg: labelLg,
    className: labelClassName,
  },
});

export const formHeader = (
  name: string,
  content: string,
  size: string,
  className?: string,
  colSizeLG?: number,
  colSizeClassName?: string,
  defaultValue?: string
) => ({
  component: Header,
  props: {
    name: name,
    size: size,
    className: className,
    content: content,
    defaultValue: defaultValue,
  },
  colSize: {
    lg: colSizeLG,
    className: colSizeClassName,
  },
});
