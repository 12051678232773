import {
  Configuration,
  EventType,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import { LoginModel } from "./generated";
import settings from "./settings.json";
import { queryClient } from "./queryClient";

const config: Configuration = {
  auth: {
    authority: settings.AzureAd.Instance + settings.AzureAd.TenantId,
    clientId: settings.AzureAd.ClientId,
    redirectUri: `https://${window.location.host}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const authenticationParameters: SilentRequest = {
  scopes: [settings.AzureAdAppScope],
};

export const authProvider = new PublicClientApplication(config);

export const checkActiveAccount = (user?: LoginModel) => {
  var accounts = authProvider.getAllAccounts();
  if (!authProvider.getActiveAccount()) {
    if (accounts?.length != 0) {
      authProvider.setActiveAccount(accounts[0]);
      queryClient.invalidateQueries(["getCurrentUserGET"]);
      return true;
    } else if (user) {
      queryClient.setQueryData(["getCurrentUserGET"], null);
      return false;
    }
  }
  return true;
};

// Default to using the first account if no account is active on page load
if (
  !authProvider.getActiveAccount() &&
  authProvider.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  authProvider.setActiveAccount(authProvider.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
authProvider.enableAccountStorageEvents();

// Listen for sign-in event and set active account
authProvider.addEventCallback((event) => {
  console.log(event.eventType);
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    queryClient.clear();
  }
});
