/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InfoURL } from '../models/InfoURL';
import type { MediaSearchFilters } from '../models/MediaSearchFilters';
import type { ResourceSearchFilters } from '../models/ResourceSearchFilters';
import type { SearchResult_Chapter } from '../models/SearchResult_Chapter';
import type { SearchResult_National } from '../models/SearchResult_National';
import type { SearchResult_Programme } from '../models/SearchResult_Programme';
import type { SearchResult_User } from '../models/SearchResult_User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SearchService {

    /**
     * Search Chapters
     * @param queryString Text to search for
     * @param entriesToTake Number of entries to find
     * @returns SearchResult_Chapter Success
     * @throws ApiError
     */
    public static searchChapterGet(
queryString?: string,
entriesToTake?: number,
): CancelablePromise<Array<SearchResult_Chapter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Search/SearchChapter',
            query: {
                'QueryString': queryString,
                'EntriesToTake': entriesToTake,
            },
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Search Nationals
     * @param queryString Text to search for
     * @param entriesToTake Number of entries to find
     * @returns SearchResult_National Success
     * @throws ApiError
     */
    public static searchNationalGet(
queryString?: string,
entriesToTake?: number,
): CancelablePromise<Array<SearchResult_National>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Search/SearchNational',
            query: {
                'QueryString': queryString,
                'EntriesToTake': entriesToTake,
            },
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Search User
     * @param queryString Text to search for
     * @param entriesToTake Number of entries to find
     * @returns SearchResult_User Success
     * @throws ApiError
     */
    public static searchUserGet(
queryString?: string,
entriesToTake?: number,
): CancelablePromise<Array<SearchResult_User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Search/SearchUser',
            query: {
                'QueryString': queryString,
                'EntriesToTake': entriesToTake,
            },
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Search User by area (NA or Chapter)
     * @param queryString Text to search for
     * @param entriesToTake Number of entries to find
     * @param area ID of NA or Chapter
     * @returns SearchResult_User Success
     * @throws ApiError
     */
    public static searchUserByAreaGet(
queryString?: string,
entriesToTake?: number,
area?: string,
): CancelablePromise<Array<SearchResult_User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Search/SearchUserByArea',
            query: {
                'QueryString': queryString,
                'EntriesToTake': entriesToTake,
                'area': area,
            },
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Search Programme
     * @param queryString Text to search for
     * @param entriesToTake Number of entries to find
     * @returns SearchResult_Programme Success
     * @throws ApiError
     */
    public static searchProgrammeGet(
queryString?: string,
entriesToTake?: number,
): CancelablePromise<Array<SearchResult_Programme>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Search/SearchProgramme',
            query: {
                'QueryString': queryString,
                'EntriesToTake': entriesToTake,
            },
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Search Resources
     * @param queryString Text to search for
     * @param entriesToTake Number of entries to find
     * @param keywords optional: Filter by keywords (separator: ';' or '|')
     * @param topicArea optional: Filter by 'Topic Area' (separator: ';' or '|')
     * @param typeOfDoc optional: Filter by 'Type of Doc' (separator: ';' or '|')
     * @param year optional: Filter by 'Year'
     * @returns InfoURL Success
     * @throws ApiError
     */
    public static searchResourceGet(
queryString?: string,
entriesToTake?: number,
keywords?: string,
topicArea?: string,
typeOfDoc?: string,
year?: string,
): CancelablePromise<Array<InfoURL>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Search/SearchResource',
            query: {
                'QueryString': queryString,
                'EntriesToTake': entriesToTake,
                'keywords': keywords,
                'topicArea': topicArea,
                'typeOfDoc': typeOfDoc,
                'year': year,
            },
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Search Resources: Get filter criteria
     * @returns ResourceSearchFilters Success
     * @throws ApiError
     */
    public static getResourceFiltersGet(): CancelablePromise<ResourceSearchFilters> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Search/GetResourceFilters',
        });
    }

    /**
     * Search Media
     * @param queryString Text to search for
     * @param entriesToTake Number of entries to find
     * @param mediaTag optional: Filter by 'media tag' (separator: ';' or '|')
     * @param programmeType optional: Filter by 'programme type' (separator: ';' or '|')
     * @param mediaYear optional: Filter by 'Year'
     * @returns InfoURL Success
     * @throws ApiError
     */
    public static searchMediaGet(
queryString?: string,
entriesToTake?: number,
mediaTag?: string,
programmeType?: string,
mediaYear?: string,
): CancelablePromise<Array<InfoURL>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Search/SearchMedia',
            query: {
                'QueryString': queryString,
                'EntriesToTake': entriesToTake,
                'mediaTag': mediaTag,
                'programmeType': programmeType,
                'mediaYear': mediaYear,
            },
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Search Media: Get filter criteria
     * @returns MediaSearchFilters Success
     * @throws ApiError
     */
    public static getMediaFiltersGet(): CancelablePromise<MediaSearchFilters> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Search/GetMediaFilters',
        });
    }

}
