import * as React from "react";
import { SemanticICONS } from "semantic-ui-react";
import { LoadingScreen } from "./views/LoadingScreen";
import loadable from "@loadable/component";
import { Rights_WebApp, Rights_Programme, Rights_User } from "./generated";
import { RouteProps } from "react-router-dom";
import EmergencyContacts from "./views/Programme/EmergencyContacts";

export interface IMenuItem extends RouteProps {
  id?: string | null;
  name: string;
  default?: boolean;
  icon?: SemanticICONS;
  path?: string;
  urlParam?: string | null;
  active?: boolean;
  accessRight?: string[];
  menu?: boolean;
  submenu?: IMenuItem[];
}
export const fallback = {
  fallback: <LoadingScreen />,
};

const UIDemo = loadable(() => import("./views/UIDemo"), fallback);
const Profile = loadable(
  () => import(/* webpackPrefetch: true */ "./views/Profile"),
  fallback
);
const EditProfile = loadable(
  () => import(/* webpackPrefetch: true */ "./views/Profile/EditProfile"),
  fallback
);
const Children = loadable(() => import("./views/Profile/Children"), fallback);
const CurriculumVitae = loadable(
  () => import("./views/Profile/CurriculumVitae"),
  fallback
);
const EForm = loadable(() => import("./views/EForms"), fallback);
const Search = loadable(() => import("./views/Search"), fallback);
const Directory = loadable(() => import("./views/Directory"), fallback);
const ManageDirectory = loadable(
  () => import("./views/Directory/ManageDirectory"),
  fallback
);
const AreaMemberList = loadable(
  () => import("./views/Directory/AreaMemberList"),
  fallback
);
const SQLQuery = loadable(() => import("./views/Admin/SQLQuery"), fallback);
const Achievements = loadable(
  () => import("./views/Admin/Achievements"),
  fallback
);
const Chapter = loadable(() => import("./views/Chapter"), fallback);
const National = loadable(() => import("./views/National"), fallback);
const User = loadable(() => import("./views/User"), fallback);
const Programme = loadable(() => import("./views/Programme"), fallback);
const PreCamp = loadable(() => import("./views/Programme/PreCamp1"), fallback);
const AddressList = loadable(
  () => import("./views/Programme/AddressList"),
  fallback
);
const Participations = loadable(
  () => import("./views/Programme/ProgrammeParticipations"),
  fallback
);
const Dif = loadable(() => import("./views/Programme/Dif"), fallback);
const TifList = loadable(() => import("./views/Programme/TifList"), fallback);
const EFormList = loadable(
  () => import("./views/Programme/EFormList"),
  fallback
);
const Programmes = loadable(
  () => import("./views/Programme/Programmes"),
  fallback
);
const MyParticipations = loadable(
  () => import("./views/Programme/Participations/MyParticipations"),
  fallback
);
const HostList = loadable(() => import("./views/Programme/Hostlist"), fallback);
const Invitations = loadable(
  () => import("./views/Programme/Invitations"),
  fallback
);
const ImportParticipations = loadable(
  () => import("./views/Programme/ImportParticipations"),
  fallback
);
const Admin = loadable(() => import("./views/Admin"), fallback);
const Roles = loadable(() => import("./views/Admin/ManageRoles"), fallback);
const Sync = loadable(() => import("./views/Admin/Sync"), fallback);
const CacheRebuild = loadable(
  () => import("./views/Admin/CacheRebuild"),
  fallback
);
const Content = loadable(() => import("./views/Content"), fallback);
const Resources = loadable(() => import("./views/Search/Resources"), fallback);
const Consent = loadable(() => import("./views/Consent"), fallback);
const FormsManager = loadable(
  () => import("./views/Admin/FormsManager"),
  fallback
);
const Media = loadable(() => import("./views/Media/index"), fallback);
const GuardianLink = loadable(
  () => import("./views/Admin/LinkGuardian"),
  fallback
);
const Finances = loadable(
  () => import("./views/Admin/Finances/Finance"),
  fallback
);
const NationalVoucher = loadable(
  () => import("./views/National/ViewNationalVoucher"),
  fallback
);
const NationalStatements = loadable(
  () => import("./views/National/ViewNationalStatement"),
  fallback
);
const ChapterVoucher = loadable(
  () => import("./views/Chapter/ViewChapterVoucher"),
  fallback
);
const ChapterStatements = loadable(
  () => import("./views/Chapter/ViewChapterStatement"),
  fallback
);
const AdminRegistration = loadable(
  () => import("./views/Admin/AdminRegistration"),
  fallback
);

export const anonRoutes: IMenuItem[] = [
  {
    id: "content",
    name: "Content",
    path: "/content",
    urlParam: "/:slug",
    component: Content,
  },
  {
    id: "resources",
    name: "Resources",
    path: "/resources",
    urlParam: "/:query?",
    component: Resources,
  },
];

export const userRoutes: IMenuItem[] = [
  {
    name: "chapter",
    path: "/chapter/:id",
    component: Chapter,
  },
  {
    name: "national",
    path: "/national/:id",
    component: National,
  },
  {
    name: "user",
    path: "/user/:upn",
    component: User,
  },
  {
    name: "programme",
    path: "/programme/:id/:upn?",
    exact: true,
    component: Programme,
  },
  {
    name: "Pre-Camp 1",
    path: "/programme/:id/precamp1/form",
    exact: true,
    component: PreCamp,
  },
  {
    name: "Address-List",
    path: "/programme/:id/addresslist/:upn?",
    exact: true,
    component: AddressList,
  },
  {
    name: "Dif",
    path: "/programme/:id/dif/:upn?",
    exact: true,
    component: Dif,
  },
  {
    name: "Tif",
    path: "/programme/:id/tif/:upn?",
    exact: true,
    component: TifList,
  },
  {
    name: "EForms",
    path: "/programme/:id/eforms/:upn?",
    exact: true,
    component: EFormList,
  },
  {
    name: "EmergencyContacts",
    path: "/programme/:id/emergencycontacts/:upn?",
    exact: true,
    component: EmergencyContacts,
  },
  {
    name: "Participations",
    path: "/programme/:id/participants/:upn?",
    exact: true,
    component: Participations,
  },
  {
    name: "search",
    path: "/search",
    urlParam: "/:category?",
    component: Search,
  },
  /*{
    name: "Personal Information",
    component: EditProfile,
    path: "/profile/edit/:upn?",
    urlParam: "/:upn?"
  },*/
  {
    name: "Personal Information",
    icon: "user circle outline",
    component: EditProfile,
    path: "/profile/edit",
    urlParam: "/:upn?",
    menu: true,
  },
  {
    name: "Children",
    icon: "child",
    component: Children,
    path: "/profile/children",
    menu: true,
  },
  {
    name: "Curriculum Vitae",
    icon: "sticky note",
    component: CurriculumVitae,
    path: "/profile/curriculumvitae",
    urlParam: "/:upn?",
    menu: true,
  },
  {
    name: "Consent",
    icon: "child",
    component: Consent,
    path: "/profile/consent",
    menu: false,
  },
  {
    icon: "heart",
    name: "Chapterstatement",
    path: "/statement/chapter",
    urlParam: "/:id",
    component: ChapterStatements,
    exact: true,
    menu: false,
  },
  {
    icon: "heart",
    name: "Chaptervoucher",
    path: "/voucher/chapter",
    component: ChapterVoucher,
    urlParam: "/:id",
    exact: true,
    menu: false,
  },
  {
    icon: "heart",
    name: "Nationalstatement",
    path: "/statement/national",
    component: NationalStatements,
    urlParam: "/:id",
    exact: true,
    menu: false,
  },
  {
    icon: "heart",
    name: "Nationalvoucher",
    path: "/voucher/national",
    component: NationalVoucher,
    urlParam: "/:id",
    exact: true,
    menu: false,
  },
  /*{
     name: "My Participation History",
     path: "/participations",
     component: MyParticipations,
     urlParam: "/:upn?"
   }*/
];

export const adminRoutes: IMenuItem[] = [
  {
    name: "Sync",
    icon: "sync",
    path: "/admin/sync",
    component: Sync,
    menu: true,
    accessRight: [Rights_WebApp.SyncAdmin],
  },
  {
    name: "Cache",
    icon: "sync",
    path: "/admin/CacheRebuild",
    component: CacheRebuild,
    menu: true,
    accessRight: [Rights_WebApp.SyncAdmin],
  },
  {
    name: "Roles",
    icon: "address card outline",
    path: "/admin/roles",
    component: Roles,
    menu: true,
    accessRight: [Rights_WebApp.RolesAdmin],
  },
  {
    name: "FormsManager",
    icon: "upload",
    path: "/admin/formsmanager",
    component: FormsManager,
    menu: true,
    accessRight: [Rights_WebApp.RolesAdmin],
  },
  {
    name: "Free Queries",
    icon: "code",
    path: "/admin/sql",
    component: SQLQuery,
    menu: true,
    accessRight: [Rights_WebApp.GlobalAdmin],
  },
  {
    name: "Achievements",
    icon: "trophy",
    path: "/admin/achievements",
    component: Achievements,
    menu: true,
    accessRight: [Rights_WebApp.GlobalAdmin],
  },
  {
    name: "Guardianlink",
    icon: "handshake",
    path: "/admin/linkguardianform",
    component: GuardianLink,
    menu: true,
    accessRight: [Rights_WebApp.GlobalAdmin],
  },
  {
    name: "Registration",
    icon: "street view",
    path: "/admin/registration",
    component: AdminRegistration,
    menu: true,
    accessRight: [Rights_WebApp.GlobalAdmin],
  },
  {
    name: "Finances",
    icon: "pound sign",
    path: "/admin/finance",
    component: Finances,
    menu: true,
    accessRight: [Rights_WebApp.GlobalAdmin, Rights_WebApp.FinanceEdit],
  },
];

export const routes: IMenuItem[] = [
  {
    id: "user",
    name: "My Profile",
    icon: "user",
    path: "/profile",
    component: Profile,
    default: true,
    submenu: userRoutes,
  },
  {
    icon: "world",
    name: "My Programmes",
    path: "/programmes",
    component: Programmes,
    urlParam: "/:upn?",
    menu: true,
    submenu: [
      {
        name: "My Participation History",
        icon: "group",
        path: "/participations",
        component: MyParticipations,
        urlParam: "/:upn?",
        menu: true,
      },
      {
        name: "Host List",
        icon: "bed",
        path: "/hostlist",
        component: HostList,
        menu: true,
      },
      {
        name: "Invitations",
        icon: "paper plane",
        path: "/invitations",
        component: Invitations,
        menu: true,
      },
      {
        name: "Participations Bulk Import",
        icon: "group",
        path: "/importParticipations",
        component: ImportParticipations,
        menu: true,
        accessRight: [
          Rights_Programme.ParticipationsEdit,
          Rights_Programme.InvitationParticipationsEdit,
        ],
      },
    ],
  },
  {
    id: "media",
    name: "Media Library",
    icon: "cloud",
    path: "/media",
    component: Media,
  },
  {
    icon: "book",
    name: "Directory",
    path: "/directory/view",
    exact: false,
    component: Directory,
    menu: true,
    submenu: [
      {
        icon: "pencil",
        name: "Manage directory",
        path: "/directory/manage",
        exact: true,
        component: ManageDirectory,
        menu: true,
        accessRight: [Rights_WebApp.ManageDirectory],
      },
      {
        icon: "list ul",
        name: "Area Memberlist",
        path: "/directory/memberlist",
        component: AreaMemberList,
        urlParam: "/:id?",
        exact: true,
        menu: true,
        accessRight: [Rights_WebApp.ManageDirectory],
      },
    ],
  },
  {
    icon: "terminal",
    name: "Demo",
    path: "/demo",
    menu: true,
    accessRight: [Rights_WebApp.Demo],
    submenu: [
      {
        icon: "cogs",
        name: "UI Demo",
        path: "/demo",
        component: UIDemo,
        menu: true,
      },
      {
        icon: "heart",
        name: "EForms",
        path: "/eform/:form/:id/:upn",
        component: EForm,
        menu: false,
      },
    ],
  },
  {
    id: "admin",
    icon: "wrench",
    name: "Administration",
    path: "/admin",
    component: Admin,
    menu: true,
    accessRight: [Rights_WebApp.Admin],
    submenu: adminRoutes,
  },
];
export const defaultMenu = routes.find((r) => r.default)!; // default

export function flatMenu(menu?: IMenuItem[]) {
  if (!menu) {
    return [];
  }
  let result: IMenuItem[] = [];
  menu.forEach((m) => {
    result.push(m);
    m.submenu && result.push(...flatMenu(m.submenu));
  });
  return result;
}

export const flatRoutes = flatMenu(routes);

export const searchRoutes = (
  query: string,
  checkRight: (right: string) => boolean
) =>
  flatRoutes.filter(
    (r) =>
      r.menu &&
      r.path &&
      (r.accessRight
        ? r.accessRight.some((right) => checkRight(right))
        : true) &&
      (r.name.toLowerCase().includes(query.toLowerCase()) ||
        (!Array.isArray(r.path) &&
          r.path &&
          r.path.toLowerCase().includes(query.toLowerCase())))
  );
