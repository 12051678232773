import * as React from "react";
import { Message, Icon } from "semantic-ui-react";

interface IErrorBoundaryProps {
  children: React.ReactNode;
}
interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Message icon negative style={{ margin: "5em", width: "fit-content" }}>
          <Icon name="frown outline" />
          <Message.Content>
            <Message.Header>Something went wrong!</Message.Header>
            <p>There was a problem with the page, please refresh.</p>
          </Message.Content>
        </Message>
      );
    }

    return this.props.children;
  }
}
