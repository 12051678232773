import {
  authProvider,
  authenticationParameters,
  checkActiveAccount,
} from "../authProvider";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export async function getHeadersWithToken(init?: RequestInit | undefined) {
  let accessToken: string | null = null;
  try {
    if (checkActiveAccount()) {
      const token = await authProvider.acquireTokenSilent(
        authenticationParameters
      );
      accessToken = token.accessToken;
    }
  } catch (e) {
    try {
      if (e instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        const token = await authProvider.acquireTokenPopup(
          authenticationParameters
        );
        accessToken = token.accessToken;
      }
      console.log(e);
    } catch (e: any) {
      // in case popup is blocked, use redirect instead
      if (
        e.name == "BrowserAuthError" ||
        e.errorCode === "popup_window_error" ||
        e.errorCode === "empty_window_error"
      ) {
        console.log(e);
        const token = await authProvider.acquireTokenRedirect(
          authenticationParameters
        );
        //accessToken = token.accessToken;
        // PAGE REDIRECTS AWAY HERE
      }
    }
  }
  let headers: HeadersInit =
    init && init.headers
      ? { ...init.headers, Authorization: "Bearer " + accessToken }
      : {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        };
  return headers;
}
